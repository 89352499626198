<template>
    <div style="background: transparent">
        <div class="search">
            <el-form ref="formInline" :model="formInline" label-width="120px">

                        <el-form-item label="订单号：">
                            <el-input v-model="formInline.orderNo"></el-input>
                        </el-form-item>

                        <el-form-item label="买家名称：">
                            <el-input  v-model="formInline.receiveName"></el-input>
                        </el-form-item>

                        <el-form-item label="供应商名称：">
                            <el-input  v-model="formInline.shopName"></el-input>
                        </el-form-item>

                        <el-form-item label="下单时间：">
                            <el-date-picker
                                v-model="formInline.createTime"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                style="width: 187px;"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>


                    <!-- <el-col :span="4">
                        <el-form-item label="订单状态：">
                            <el-select v-model="formInline.status">
                                <el-option value="1" label="待付款"></el-option>
                                <el-option value="2" label="待发货"></el-option>
                                <el-option value="3" label="待收货"></el-option>
                                <el-option value="4" label="待评价"></el-option>
                                <el-option value="5" label="已完成"></el-option>
                                <el-option value="6" label="已取消"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="4">
                        <el-form-item label="审核状态：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col> -->
                    <div style="margin-bottom: 22px; display: inline-block; vertical-align: middle; padding-left: 30px">
                        <el-button type="primary" @click="search" icon="el-icon-search">查询</el-button>
                        <a class="clear cp" @click="clearSearch">清空</a>
                    </div>

            </el-form>
        </div>

        <div class="content">
            <h4>订单列表</h4>
            <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="全部" name="0"></el-tab-pane>
                <el-tab-pane label="待付款" name="1"></el-tab-pane>
                <el-tab-pane label="待发货" name="2"></el-tab-pane>
                <el-tab-pane label="待收货" name="3"></el-tab-pane>
                <el-tab-pane label="待评价" name="4"></el-tab-pane>
                <el-tab-pane label="已完成" name="5"></el-tab-pane>
                <el-tab-pane label="已取消" name="6"></el-tab-pane>
            </el-tabs>

            <div style="padding: 0 24px">
                <div class="flex order_table_title">
                    <div style="width: 20%">商品</div>
                    <div style="width: 10%">供应商名称</div>
                    <div style="width: 15%; text-align: center">买家名称</div>
                    <div style="width: 8%; text-align: center">金额</div>
                    <div style="width: 7%; text-align: center">数量</div>
                    <div style="width: 10%; text-align: center">发货状态</div>
                    <div style="width: 10%; text-align: center">订单状态</div>
                    <div style="width: 10%; text-align: center">付款状态</div>
                    <div style="width: 10%; text-align: center">操作</div>
                </div>

                <div class="order_table mt12" v-if="tableData.length > 0" v-for="(item,index) in tableData" :key="index">
                    <div class="order_table_order flex pt10 pb10">
                        <div class="mr50" style="color: #1D1E1F;">订单号：{{item.orderNo}}</div>
                        <div style="color: rgba(29,30,31,0.5);">下单时间：{{item.createTime}}</div>
                    </div>
                    <div v-for="(itm,inx) in item.orders" :key="inx">
                        <div v-if="item.isSplit == 1" class="pl24" style="height: 40px; border: 1px solid #E6E6E6; border-top: 0; line-height: 40px">
                            订单号：{{itm.orderNo}}
                        </div>

                        <div v-for="(it,ix) in itm.orderDetails" :key="ix" class="order_table_content flex">
                            <div style="width: 20%" class="flex">
                                <el-image :src="it.picUrl" style="width: 25%"></el-image>
                                <div class="ml12">
                                    <div>{{it.spuName}}</div>
                                    <div style="color: rgba(29, 30, 31, 0.5)">{{it.skuSpecName}}：{{it.skuSpecValue}}</div>
                                </div>
                            </div>
                            <div style="width: 10%">{{itm.shopName}}</div>
                            <div style="width: 15%; text-align: center">{{itm.receiveName}}</div>
                            <div style="width: 8%; text-align: center">
                                <div style="color: #F53333; font-weight:600; text-align: center">¥ {{adddh(it.skuPrice)}}</div>
                                <div style="text-align: center">(运费：¥ {{it.freightPrice == null ? '0.00' : it.freightPrice}})</div>
                            </div>
                            <div style="width: 7%; text-align: center">{{it.quantity}}{{it.skuUnit}}</div>
                            <div style="width: 10%; text-align: center">{{itm.sendStatus == 1 ? '部分发货' : (itm.sendStatus == 2 ? '全部发货' : '未发货')}}</div>
                            <div style="width: 10%; text-align: center">{{statusChange(itm.status)}}</div>
                            <div style="width: 10%; text-align: center">{{itm.paymentTime == null ? '未付款' : '已付款'}}</div>
                            <div style="width: 10%; text-align: center; cursor: pointer" @click="goDetail(itm.id)">查看订单</div>
                        </div>
                    </div>
                    
                </div>
                <el-empty :image-size="100" v-if="tableData.length == 0"></el-empty>
            </div>

            <div style="text-align: right; padding: 0 24px;">
                <div style="margin-top: 12px; display: inline-block">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageInfo.pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: '',// 记录id
            dialogdFormVisible: false,
            activeName: '0',
            total: 0,
            tableData: [],
            formInline: {
                orderNo: '',
                receiveName: '',
                shopName: '',
                createTime: '',
                status: ''
            },
            pageInfo: {
                pageNo: 1,
                pageSize: 10,
            },
            approveReason: ''
        }
    },
     mounted(){
         this.search()
    },
    methods:{
        search(){
            let params = {
                ...this.pageInfo,
                ...this.formInline
            }
            this.qa.orderSearch(params).then(res => {
                this.tableData = res.data;
                this.total = res.total;
            })
        },

        handleClickTab(e,event) {
            if( this.activeName == '0') {
                this.formInline.status = ''
            }else {
                this.formInline.status = this.activeName
            }
            // this.activeName = 
            this.search()
        },
        handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.search()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.search()
        },
        clearSearch() {
            this.formInline = {
                orderNo: '',
                receiveName: '',
                shopName: '',
                createTime: '',
                status: ''
            };
            this.search()
        },

        goDetail(id){
            this.$router.push({
                path: 'orderDetail',
                query: {
                    id: id
                }
            })
        },


        //列表订单状态
        statusChange(id){
            if(id == 1) {
                return '待付款'
            } else if(id == 2) {
                return '待发货'
            } else if(id == 3) {
                return '待收货'
            } else if(id == 4) {
                return '待评价'
            } else if(id == 5) {
                return '已完成'
            } else if(id == 6) {
                return '已取消'
            }
        },
        //逗号算法
        adddh(val){
            
            var str = this.highPrecisionDiv(val,10000).toFixed(2).toString();
            // console.log(str)
            var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
            val =  str.replace(reg,"$1,");
            return val
        },
    }
}
</script>

<style scoped>
  .search {
      background: #fff;
      padding: 24px;
      padding-bottom: 0;
  }
  .search >>> .el-form .el-form-item {
      display: inline-block;
      vertical-align: middle;
  }
  .search >>> .el-form .el-input__inner {
      height: 34px;
  }
  .search >>> .el-button {
      padding: 9px 20px;
  }
  .search >>> .el-col {
      height: 34px;
  }
  .search >>> .el-form-item__label {
      line-height: 34px;
  }
  .search >>> .el-form-item__content {
      line-height: 34px;
  }
  .content >>> .el-tabs__nav-wrap {
      padding: 0 28px;
  }
  .content >>> .el-tabs .is-active {
      background: rgba(25, 145, 252, 0.1);
  }
  .content >>> .el-tabs .el-tabs__item {
      /* padding: 0; */
      

  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px;
  }
  .clear {
      font-size: 14px;
      color: #8C9099;
      margin-left: 24px;
  }
  .content {
      /* padding: 24px; */
      padding: 24px 0;
      background: #fff;
      margin-top: 14px;
  }
  .content >h4 {
      padding: 0 24px;
      margin-bottom: 20px;
  }

  .wRound {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #F53333;
  }
  .nRound {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #F53333;
  }
  .order_table_title {
      height: 46px;
      background: #F8F8F8;
      font-size: 14px;
      line-height: 46px;
      color: #909399;
      padding: 0 24px;
  }
  .order_table_title >div {
      padding: 0 14px;
  }
  .order_table {
      border: 1px solid #E6E6E6;
      
      font-size: 14px;
  }
  .order_table_order {
      padding: 0 24px;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #E6E6E6;
      background: rgba(24, 144, 251, 0.06);
  }
  .order_table_content {
      padding: 20px;
      border-bottom: 1px solid #e6e6e6;
  }
  .order_table_content >div {
      padding: 0 14px;
  }
</style>